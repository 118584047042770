
import {
  arrayOf,
  bool,
  nullable,
  oneOf,
  oneOfType,
  shape,
  string,
} from 'vue-types';
import {
  COLOR_SCHEME_BLUE,
  COLOR_SCHEME_DARK,
  COLOR_SCHEME_WHITE,
  SIZE_MEDIUM,
  ALIGNMENT_LEFT,
  ALIGNMENT_RIGHT,
} from '~/constants/General';
import { customLinkFieldShape, imageShape } from '~/constants/PropTypes';

export default {
  inheritAttrs: false,
  props: {
    colorScheme: oneOf([
      COLOR_SCHEME_WHITE,
      COLOR_SCHEME_DARK,
      COLOR_SCHEME_BLUE,
    ]).def(COLOR_SCHEME_WHITE),
    visual: arrayOf(shape(imageShape).loose),
    link: arrayOf(shape(customLinkFieldShape).loose),
    alignment: oneOf([ALIGNMENT_LEFT, ALIGNMENT_RIGHT]).def(ALIGNMENT_LEFT),
    heading: oneOfType([string(), nullable()]),
    body: oneOfType([string(), nullable()]),
    boxed: bool().def(false),
  },
  data() {
    return {
      SIZE_MEDIUM,
      COLOR_SCHEME_DARK,
      COLOR_SCHEME_BLUE,
      COLOR_SCHEME_WHITE,
      ALIGNMENT_LEFT,
      ALIGNMENT_RIGHT,
    };
  },
};
